import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram, faTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";
import * as dayjs from "dayjs";

export default function PageFooter() {

   const copyrightYear = dayjs().year();

   return <div className="footer">
      <div className="footer-container">
         <div>
            <div className="footer-info-container">
               <div className="footer-info">
                  <h1>About Us</h1>
                  <div><a href="/about/lovetheball.html">Love the Ball</a></div>
                  <div><a href="/about/academy.html">The Academy</a></div>
                  <div><a href="/about/coaches.html">Coaches</a></div>
                  <div><a href="/about/governance.html">Governance</a></div>
                  <div><a href="/gdpr.html">Your Data</a></div>
                  <div><a href="/covid-19.html">COVID-19 Update</a></div>
                  <div><a href="/cancellations.html">Cancellations &amp; Refunds</a></div>
               </div>
            </div>
            <div className="footer-info-container">
               <div className="footer-info">
                  <h1>Courses</h1>
                  <div><a href="/courses/overview.html">Overview</a></div>
                  <div><a href="/courses/schools.html">Schools</a></div>
                  <div><a href="/courses/holidaycamps.html">Holiday Camps</a></div>
                  <div><a href="/courses/afterschoolclub.html">After School Club</a></div>
               </div>
            </div>
            <div className="footer-info-container">
               <div className="footer-info">
                  <h1>Contact Us</h1>
                  <div>Telephone: 07936 525 577</div>
                  <div><a href="mailto:info@lovetheball.com">info@lovetheball.com</a></div>
               </div>
            </div>
            <div style={{clear: "both"}}/>
         </div>
         <div className="social-media-container">
            <h1>Follow Love the Ball</h1>
            <div className="social-media">
               <a href="https://www.facebook.com/people/Love-the-Ball/100087144263207/">
                  <FontAwesomeIcon icon={faFacebook} size="3x"/>
               </a>
            </div>
            <div className="social-media">
               <a href="https://twitter.com/_LoveTheBall">
                  <FontAwesomeIcon icon={faTwitter} size="3x"/>
               </a>
            </div>
            <div className="social-media">
               <a href="http://www.youtube.com/channel/UCZ1JTpHUrix255DVxG6yy_g?feature=watch">
                  <FontAwesomeIcon icon={faYoutube} size="3x"/>
               </a>
            </div>
            <div className="social-media">
               <a href="https://www.instagram.com/lovetheball_surrey/">
                  <FontAwesomeIcon icon={faInstagram} size="3x"/>
               </a>
            </div>
         </div>
         <div className="sponsor-container">
            <h1>In Partnership With</h1>
            <div className="sponsor-link">
               <a href="https://djrcoaching.co.uk">
                  <img src="/img/sponsors/DJR-Logo-Final-WO.png"/>
               </a>
            </div>
         </div>
      </div>
      <div className="footer-copyright">
         Copyright &copy; 2014-{copyrightYear}. Love The Ball Limited. All rights
         reserved.<br/>
         Website Design by Uncle Just
      </div>
   </div>
}