import SessionHeader from "./SessionHeader";
import {CobySessionContext} from "@unclejust/coby-client/src/utils/SessionInfo";

export default function PageHeader() {

   return <div className="header">
      <div style={{padding: "20px", position: "relative"}}>
         <div className="logo">
            <a href="/index.html">
               <img src="/img/brand/LTB_Academy_Transparent.svg" alt="LTB Academy Logo"/>
            </a>
         </div>
         <div className="nav-container">
            <nav>
               <div className="header-title">
                  <a href="/index.html">EDUCATION <span style={{fontWeight: "normal"}}>THROUGH SPORTS</span></a>
               </div>
               <div className="main-nav">
                  <span><a href="/courses/schools.html">Schools</a></span>|
                  <span><a href="/courses/holidaycamps.html">Holiday Camps</a></span>|
                  <span><a href="/courses/afterschoolclub.html">After School Club</a></span>|
                  <span><a href="/parties.html">Parties</a></span>|
                  <span><a href="/about/coaches.html">Coaches</a></span>|&nbsp;
                  <span className="book-a-course"><a href="/book-a-course.html">Book Now</a></span>&nbsp;
                  <span className="covid-19"><a href="/covid-19.html">COVID-19</a></span>
               </div>
               <CobySessionContext.Consumer>
                  {session => session.customerLoggedIn && <div>
                     <div className="member-nav">
                        {`${session.info.customer.firstName}:`}
                        <span><a href="/customer/profile.html">Profile</a></span>|
                        <span><a href="/customer/basket.html">Basket</a></span>|
                        <span><a href="/customer/bookings.html">Bookings</a></span>|
                        <span><a href="/amoge/api/logout?userType=com.unclejust.coby.data.Customer">Logout</a></span>
                     </div>
                  </div>}
               </CobySessionContext.Consumer>
            </nav>
         </div>
         <div style={{clear: "both"}}/>
         <SessionHeader/>
      </div>
   </div>

}