import {useState, useEffect} from "react";
import AmogeServer from "@unclejust/amoge-client/src/utils/AmogeServer";

export type CobySettings = {
   stripeEnabled: boolean,
   payPalEnabled: boolean,
   payPalVersion: 1 | 2,
   childcareVouchersEnabled: boolean,
   refundsEnabled: boolean
}

export default function useCobySettings(): CobySettings {
   const [stripeEnabled, setStripeEnabled] = useState(false);
   const [payPalEnabled, setPayPalEnabled] = useState(false);
   const [payPalVersion, setPayPalVersion] = useState<1 | 2>(1);
   const [childcareVouchersEnabled, setChildcareVouchersEnabled] = useState(false);
   const [refundsEnabled, setRefundsEnabled] = useState(false);

   useEffect(() => {
      AmogeServer("/amoge/api/client-properties", {}, false).then(clientProperties => {
         const payPalProperty = clientProperties["unclejust.coby.payment.paypal"];
         const payPalVersionProperty = clientProperties["unclejust.coby.payment.paypal.version"];
         const stripeProperty = clientProperties["unclejust.coby.payment.stripe"];
         const childcareVouchersProperty = clientProperties["unclejust.coby.payment.vouchers.childcare"]
         const refundsProperty = clientProperties["unclejust.coby.payment.refunds"];

         const trueRegex = /^true$/i;

         payPalProperty && setPayPalEnabled(trueRegex.test(payPalProperty));
         payPalVersionProperty && setPayPalVersion(payPalVersionProperty)
         stripeProperty && setStripeEnabled(trueRegex.test(stripeProperty));
         childcareVouchersProperty && setChildcareVouchersEnabled(trueRegex.test(childcareVouchersProperty))
         refundsProperty && setRefundsEnabled(trueRegex.test(refundsProperty));
      })
   }, [])

   return {stripeEnabled, payPalEnabled, payPalVersion, childcareVouchersEnabled, refundsEnabled}
}