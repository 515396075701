import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {CobySessionContext} from "@unclejust/coby-client/src/utils/SessionInfo";
import useCobySettings from "@unclejust/coby-client/src/api/CobySettings";

export default function SessionHeader() {

   const {stripeEnabled} = useCobySettings();

   const [adminMenuVisible, setAdminMenuVisible] = useState(false);

   const toggleAdminMenu = evt => {
      evt.preventDefault();
      setAdminMenuVisible(prev => !prev);
   }

   const agentOrSysAdminLoggedIn = session => session.agentLoggedIn || session.sysAdminLoggedIn;

   return <div className="session-header">
      <CobySessionContext.Consumer>
         {session => <>
            {agentOrSysAdminLoggedIn(session) && <div style={{position: "relative"}}>
               <div className="session-header-content admin">
                  <span>ADMIN</span>
                  <span>
            <a href="" onClick={toggleAdminMenu}>
               <FontAwesomeIcon icon={faBars}/>
            </a>
         </span>
               </div>
               {adminMenuVisible && <div className="coby-menu">
                  <a href="/coby/admin/club-list.html">Clubs</a>
                  <a href="/coby/admin/course-list.html">Courses</a>
                  <a href="/coby/admin/coach-list.html">Coaches</a>
                  <a href="/coby/admin/customer-list.html">Customers</a>
                  <a href="/coby/admin/statistics.html">Statistics</a>
                  {session.sysAdminLoggedIn && <a href="/coby/sysadmin/email-log.html">E-mails</a>}
                  {session.sysAdminLoggedIn && <a href="/coby/sysadmin/user-roles.html">Users &amp; Roles</a>}
                  <a href="/admin/promotion.html">Promotion</a>
                  <a href="/amoge/api/logout">Log Out</a>
               </div>}
            </div>}
            {!agentOrSysAdminLoggedIn(session) && session.customerLoggedIn && <div>
               <div className="session-header-content">
                  {session.info.customer && session.info.customer.firstName} &#160;
                  <a href="/amoge/api/logout?userType=com.unclejust.coby.data.Customer">Logout</a>
               </div>
            </div>}
            {!agentOrSysAdminLoggedIn(session) && !session.customerLoggedIn && <div>
               <div className="session-header-content">
                  <a href="/login.html">Login</a>
                  <a href="/registration/customer-registration.html">Register</a>
               </div>
            </div>}
         </>}
      </CobySessionContext.Consumer>
   </div>
}