import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import "./PagePresenter.css";
import {CobySessionContext, useCobySession} from "@unclejust/coby-client/src/utils/SessionInfo";
import GoToCoby from "@unclejust/coby-client/src/components/GoToCoby";
import * as React from "react";
import {AmogeSessionContext} from "@unclejust/amoge-client/src/utils/SessionInfo";

export type PagePresenterProps = {
   children: React.ReactNode
}

export default function PagePresenter(props: PagePresenterProps) {
   const cobySession = useCobySession();

   return <div><GoToCoby/>
      <AmogeSessionContext.Provider value={cobySession}>
         <CobySessionContext.Provider value={cobySession}>
            <PageHeader/>
            {props.children}
            <PageFooter/>
         </CobySessionContext.Provider>
      </AmogeSessionContext.Provider>
   </div>;
}