import * as React from "react";

import {
   AmogeSession,
   AmogeSessionInfo,
   useAmogeSession
} from "@unclejust/amoge-client/src/utils/SessionInfo"
import {Customer} from "../api/Customer";
import {TeamMembership} from "../api/TeamMembership";
import {Club} from "../api/Club";

export const CobySessionContext = React.createContext<CobySession>(undefined);

export type CobySessionInfo = AmogeSessionInfo & {
   sysAdmin?: {id: number, email: string, firstName: string, lastName: string},
   agent?: {id: number, email: string, firstName: string, lastName: string, defaultClubId: number}
   customer?: Customer,
   club?: Club,
   teamMembership: TeamMembership[]
}

export type CobySession = AmogeSession<CobySessionInfo> & {
   agentLoggedIn: boolean,
   sysAdminLoggedIn: boolean,
   customerLoggedIn: boolean,
   canDoAgent: boolean,
   teamMembership: TeamMembership[]
}

export function useCobySession(): CobySession {
   const amogeSession = useAmogeSession<CobySessionInfo>();
   const canDoAgent = amogeSession.userCanDoAny(["COBY_AGENT"]);
   const cobySessionInfo = amogeSession.info;
   const userTypes = cobySessionInfo.userTypes || [];
   const agentLoggedIn = userTypes.indexOf("CobyAgent") >= 0;
   const sysAdminLoggedIn = userTypes.indexOf("CobySysAdmin") >= 0;
   const customerLoggedIn = userTypes.indexOf("Customer") >= 0;
   const teamMembership = cobySessionInfo.teamMembership || [];
   return {...amogeSession, agentLoggedIn, sysAdminLoggedIn, customerLoggedIn, canDoAgent, teamMembership}
}