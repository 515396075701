import {useEffect, useState} from "react";

export function GoToAmoge() {
   const goToAmogeAction = () => {
      window.location.href = "/amoge"
   }

   return <CommandKeys keys="gotoamoge" action={goToAmogeAction}/>
}

export type CommandKeysProps = {
   keys: string
   action: (...args: any[]) => void
}

export default function CommandKeys(props: CommandKeysProps) {
   const [bodyKeys, setBodyKeys] = useState("");

   useEffect(() => {
      document.addEventListener("keypress", evt => {
         if ((evt.target as HTMLElement).tagName === "BODY") {
            setBodyKeys(prev => {
               prev = `${prev}${evt.key}`
               if (prev.length > props.keys.length) {
                  prev = prev.slice(1);
               }
               return prev;
            })
         }
      })
   }, [])

   useEffect(() => {
      if (bodyKeys.toLowerCase() === props.keys) {
         props.action();
      }
   }, [bodyKeys])

   return <></>
}